<template>
  <div class="signature">
    <div
      class="trans"
      :style="{ height: windowHeight() + 'px', width: '44px' }"
      @click="signReturnFun"
    >
      <!-- <span
        class="sign-head"
        :style="{ height: windowHeight() + 'px', width: '44px' }"
        >请横置手写签名</span
      > -->
      <img
        class="sign-head-img"
        :style="{ height: windowHeight() + 'px', width: '44px' }"
        src="@/assets/img/sign1.png"
        alt=""
      />
    </div>
    <div class="sings">
      <sign
        ref="sign"
        :place-holder-str="placeHolderStr"
        :height="windowHeight()"
        :width="windowWidth() - 99"
        :receiverName="receiverName"
      ></sign>
    </div>
    <div :style="{ height: windowHeight() + 'px', width: '50px' }" class="">
      <span
        class="complete1"
        :style="{ height: windowHeight() / 2 + 'px', width: '50px' }"
        @click="clearFun"
      >
        <span
          class="complete1-span"
          :style="{ lineHeight: windowHeight() / 2 + 'px' }"
          >重置</span
        >
        <img
          class="complete1-img"
          :style="{ height: windowHeight() / 2 + 'px', width: '50px' }"
          src="@/assets/img/sign2.png"
          alt=""
        />
      </span>
      <span
        class="complete2"
        :style="{ height: windowHeight() / 2 + 'px', width: '50px' }"
        @click="completeFun"
      >
        <span
          class="complete2-span"
          :style="{ lineHeight: windowHeight() / 2 + 'px' }"
          >完成</span
        >
        <img
          class="complete2-img"
          :style="{ height: windowHeight() / 2 + 'px', width: '50px' }"
          src="@/assets/img/sign3.png"
          alt=""
        />
      </span>
    </div>
  </div>
</template>

<script>
import rotateBase64Img from '../commons/rotateBase64'
import { defineComponent, ref } from 'vue'
import sign from '@/components/sign1.vue'
import { throwCustomFunction } from '../commons/ErrorHandle'
import AxiosAdapter from '../request/AxiosAdapter'
export default defineComponent({
  emits: ['sign-event', 'signreturn-event'],
  name: 'signature',
  props: {
    receiverName: String,
    placeHolderStr: String
  },
  components: {
    sign
  },
  setup(props, context) {
    const signSrc = ref()
    const sign = ref()
    const rotateCallback = (base64data) => {
      context.emit('sign-event', base64data)
    }
    const completeFun = () => {
      if (sign.value.linen.length < 40) {
        throwCustomFunction('签名不合法signature')
      }
      signSrc.value = sign.value.submit(80, 50)
      // AxiosAdapter.post({
      //   url: '/webElectronic/recordBase64Log',
      //   data: { logBase64: signSrc.value }
      // })

      rotateBase64Img(signSrc.value, -90, rotateCallback)
    }

    const signReturnFun = () => {
      context.emit('signreturn-event')
    }
    const windowHeight = () => {
      return (
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      )
    }
    const windowWidth = () => {
      return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      )
    }

    const clearFun = async () => {
      console.log('清空了')
      sign.value.clear()
    }
    return {
      signSrc,
      sign,
      completeFun,
      windowHeight,
      windowWidth,
      clearFun,
      signReturnFun,
      rotateCallback
    }
  }
})
</script>
<style lang="scss" scoped>
.signature {
  display: flex;
  flex-direction: row-reverse;
}
.trans {
  position: relative;
}
.sign-head {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}
.sign-head-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.btn {
  height: 50px;
  .el-button {
    height: 50px;
    width: 50%;
    margin: 0;
  }
}
.complete1 {
  display: inline-block;
  text-align: center;
  position: relative;
}
.complete2 {
  display: inline-block;
  text-align: center;
  background: #45b2ef;
  position: relative;
}
.complete1-span {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}
.complete1-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.complete2-span {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}
.complete2-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
</style>
